@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.main__container {
  width: 100%;
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;
}

.main__container>* {
  text-align: center;
}

.main__logo {
  font-family: 'Playfair Display', serif;
  font-size: 22px;
  border-color: var(--black);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  padding: 0 2px;
  width: fit-content;
  margin: auto;
}

.main__form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.form__content {
  /* display: flex; */
  /* flex-direction: row; */
  /* justify-content: center; */
  /* align-items: center; */
}

.form__later {
  /* margin: 0 16px; */
  /* box-sizing: border-box; */
  /* padding-left: 0; */
  margin: 20px auto;
  /* width: fit-content; */
}

@media screen and (max-width: 570px) {
  .main__container {
    flex-direction: column;
    justify-content: space-evenly;
  }
}