.quote {
  box-sizing: border-box;
  padding: 8px;
  margin: 8px 16px;
  width: calc(100% -32px);
  border: 1px solid #76787a;
}

figure {
  margin: 0;
  padding: 0;
}

figcaption {
  text-align: right;
}
.vkuiIconButton {
  height: 0;
}
.vkuiIconButton>* {
  padding: 0 !important;
}