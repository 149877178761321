@font-face {
	font-family: 'iconfont';
	src:url('../fonts/iconfont.eot?-8vg322');
	src:url('../fonts/iconfont.eot?#iefix-8vg322') format('embedded-opentype'),
		url('../fonts/iconfont.woff?-8vg322') format('woff'),
		url('../fonts/iconfont.ttf?-8vg322') format('truetype'),
		url('../fonts/iconfont.svg?-8vg322#iconfont') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'iconfont';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-user-female:before {
	content: "\e106";
}
.icon-user-follow:before {
	content: "\e064";
}
.icon-user-following:before {
	content: "\e065";
}
.icon-user-unfollow:before {
	content: "\e066";
}
.icon-trophy:before {
	content: "\e067";
}
.icon-screen-smartphone:before {
	content: "\e068";
}
.icon-screen-desktop:before {
	content: "\e069";
}
.icon-plane:before {
	content: "\e06a";
}
.icon-notebook:before {
	content: "\e06b";
}
.icon-moustache:before {
	content: "\e06c";
}
.icon-mouse:before {
	content: "\e06d";
}
.icon-magnet:before {
	content: "\e06e";
}
.icon-energy:before {
	content: "\e06f";
}
.icon-emoticon-smile:before {
	content: "\e070";
}
.icon-disc:before {
	content: "\e071";
}
.icon-cursor-move:before {
	content: "\e072";
}
.icon-crop:before {
	content: "\e073";
}
.icon-credit-card:before {
	content: "\e074";
}
.icon-chemistry:before {
	content: "\e075";
}
.icon-user:before {
	content: "\e076";
}
.icon-speedometer:before {
	content: "\e077";
}
.icon-social-youtube:before {
	content: "\e078";
}
.icon-social-twitter:before {
	content: "\e079";
}
.icon-social-tumblr:before {
	content: "\e07a";
}
.icon-social-facebook:before {
	content: "\e07b";
}
.icon-social-dropbox:before {
	content: "\e07c";
}
.icon-social-dribbble:before {
	content: "\e07d";
}
.icon-shield:before {
	content: "\e07e";
}
.icon-screen-tablet:before {
	content: "\e07f";
}
.icon-magic-wand:before {
	content: "\e080";
}
.icon-hourglass:before {
	content: "\e081";
}
.icon-graduation:before {
	content: "\e082";
}
.icon-ghost:before {
	content: "\e083";
}
.icon-game-controller:before {
	content: "\e084";
}
.icon-fire:before {
	content: "\e085";
}
.icon-eyeglasses:before {
	content: "\e086";
}
.icon-envelope-open:before {
	content: "\e087";
}
.icon-envelope-letter:before {
	content: "\e088";
}
.icon-bell:before {
	content: "\e089";
}
.icon-badge:before {
	content: "\e08a";
}
.icon-anchor:before {
	content: "\e08b";
}
.icon-wallet:before {
	content: "\e08c";
}
.icon-vector:before {
	content: "\e08d";
}
.icon-speech:before {
	content: "\e08e";
}
.icon-puzzle:before {
	content: "\e08f";
}
.icon-printer:before {
	content: "\e090";
}
.icon-present:before {
	content: "\e091";
}
.icon-playlist:before {
	content: "\e092";
}
.icon-pin:before {
	content: "\e093";
}
.icon-picture:before {
	content: "\e094";
}
.icon-map:before {
	content: "\e095";
}
.icon-layers:before {
	content: "\e096";
}
.icon-handbag:before {
	content: "\e097";
}
.icon-globe-alt:before {
	content: "\e098";
}
.icon-globe:before {
	content: "\e099";
}
.icon-frame:before {
	content: "\e09a";
}
.icon-folder-alt:before {
	content: "\e09b";
}
.icon-film:before {
	content: "\e09c";
}
.icon-feed:before {
	content: "\e09d";
}
.icon-earphones-alt:before {
	content: "\e09e";
}
.icon-earphones:before {
	content: "\e09f";
}
.icon-drop:before {
	content: "\e0a0";
}
.icon-drawer:before {
	content: "\e0a1";
}
.icon-docs:before {
	content: "\e0a2";
}
.icon-directions:before {
	content: "\e0a3";
}
.icon-direction:before {
	content: "\e0a4";
}
.icon-diamond:before {
	content: "\e0a5";
}
.icon-cup:before {
	content: "\e0a6";
}
.icon-compass:before {
	content: "\e0a7";
}
.icon-call-out:before {
	content: "\e0a8";
}
.icon-call-in:before {
	content: "\e0a9";
}
.icon-call-end:before {
	content: "\e0aa";
}
.icon-calculator:before {
	content: "\e0ab";
}
.icon-bubbles:before {
	content: "\e0ac";
}
.icon-briefcase:before {
	content: "\e0ad";
}
.icon-book-open:before {
	content: "\e0ae";
}
.icon-basket-loaded:before {
	content: "\e0af";
}
.icon-basket:before {
	content: "\e0b0";
}
.icon-bag:before {
	content: "\e0b1";
}
.icon-action-undo:before {
	content: "\e0b2";
}
.icon-action-redo:before {
	content: "\e0b3";
}
.icon-wrench:before {
	content: "\e0b4";
}
.icon-umbrella:before {
	content: "\e0b5";
}
.icon-trash:before {
	content: "\e0b6";
}
.icon-tag:before {
	content: "\e0b7";
}
.icon-support:before {
	content: "\e0b8";
}
.icon-size-fullscreen:before {
	content: "\e0b9";
}
.icon-size-actual:before {
	content: "\e0ba";
}
.icon-shuffle:before {
	content: "\e0bb";
}
.icon-share-alt:before {
	content: "\e0bc";
}
.icon-share:before {
	content: "\e0bd";
}
.icon-rocket:before {
	content: "\e0be";
}
.icon-question:before {
	content: "\e0bf";
}
.icon-pie-chart:before {
	content: "\e0c0";
}
.icon-pencil:before {
	content: "\e0c1";
}
.icon-note:before {
	content: "\e0c2";
}
.icon-music-tone-alt:before {
	content: "\e0c3";
}
.icon-music-tone:before {
	content: "\e0c4";
}
.icon-microphone:before {
	content: "\e0c5";
}
.icon-loop:before {
	content: "\e0c6";
}
.icon-logout:before {
	content: "\e0c7";
}
.icon-login:before {
	content: "\e0c8";
}
.icon-list:before {
	content: "\e0c9";
}
.icon-like:before {
	content: "\e0ca";
}
.icon-home:before {
	content: "\e0cb";
}
.icon-grid:before {
	content: "\e0cc";
}
.icon-graph:before {
	content: "\e0cd";
}
.icon-equalizer:before {
	content: "\e0ce";
}
.icon-dislike:before {
	content: "\e0cf";
}
.icon-cursor:before {
	content: "\e0d0";
}
.icon-control-start:before {
	content: "\e0d1";
}
.icon-control-rewind:before {
	content: "\e0d2";
}
.icon-control-play:before {
	content: "\e0d3";
}
.icon-control-pause:before {
	content: "\e0d4";
}
.icon-control-forward:before {
	content: "\e0d5";
}
.icon-control-end:before {
	content: "\e0d6";
}
.icon-calendar:before {
	content: "\e0d7";
}
.icon-bulb:before {
	content: "\e0d8";
}
.icon-bar-chart:before {
	content: "\e0d9";
}
.icon-arrow-up:before {
	content: "\e0da";
}
.icon-arrow-right:before {
	content: "\e0db";
}
.icon-arrow-left:before {
	content: "\e0dc";
}
.icon-arrow-down:before {
	content: "\e0dd";
}
.icon-ban:before {
	content: "\e0de";
}
.icon-bubble:before {
	content: "\e0df";
}
.icon-camcorder:before {
	content: "\e0e0";
}
.icon-camera:before {
	content: "\e0e1";
}
.icon-check:before {
	content: "\e0e2";
}
.icon-clock:before {
	content: "\e0e3";
}
.icon-close:before {
	content: "\e0e4";
}
.icon-cloud-download:before {
	content: "\e0e5";
}
.icon-cloud-upload:before {
	content: "\e0e6";
}
.icon-doc:before {
	content: "\e0e7";
}
.icon-envelope:before {
	content: "\e0e8";
}
.icon-eye:before {
	content: "\e0e9";
}
.icon-flag:before {
	content: "\e0ea";
}
.icon-folder:before {
	content: "\e0eb";
}
.icon-heart:before {
	content: "\e0ec";
}
.icon-info:before {
	content: "\e0ed";
}
.icon-key:before {
	content: "\e0ee";
}
.icon-link:before {
	content: "\e0ef";
}
.icon-lock:before {
	content: "\e0f0";
}
.icon-lock-open:before {
	content: "\e0f1";
}
.icon-magnifier:before {
	content: "\e0f2";
}
.icon-magnifier-add:before {
	content: "\e0f3";
}
.icon-magnifier-remove:before {
	content: "\e0f4";
}
.icon-paper-clip:before {
	content: "\e0f5";
}
.icon-paper-plane:before {
	content: "\e0f6";
}
.icon-plus:before {
	content: "\e0f7";
}
.icon-pointer:before {
	content: "\e0f8";
}
.icon-power:before {
	content: "\e0f9";
}
.icon-refresh:before {
	content: "\e0fa";
}
.icon-reload:before {
	content: "\e0fb";
}
.icon-settings:before {
	content: "\e0fc";
}
.icon-star:before {
	content: "\e0fd";
}
.icon-symbol-female:before {
	content: "\e0fe";
}
.icon-symbol-male:before {
	content: "\e0ff";
}
.icon-target:before {
	content: "\e100";
}
.icon-volume-1:before {
	content: "\e101";
}
.icon-volume-2:before {
	content: "\e102";
}
.icon-volume-off:before {
	content: "\e103";
}
.icon-users:before {
	content: "\e104";
}
.icon-mobile:before {
	content: "\e000";
}
.icon-laptop:before {
	content: "\e001";
}
.icon-desktop:before {
	content: "\e002";
}
.icon-tablet:before {
	content: "\e003";
}
.icon-phone:before {
	content: "\e004";
}
.icon-document:before {
	content: "\e005";
}
.icon-documents:before {
	content: "\e006";
}
.icon-search:before {
	content: "\e007";
}
.icon-clipboard:before {
	content: "\e008";
}
.icon-newspaper:before {
	content: "\e009";
}
.icon-notebook2:before {
	content: "\e00a";
}
.icon-book-open2:before {
	content: "\e00b";
}
.icon-browser:before {
	content: "\e00c";
}
.icon-calendar2:before {
	content: "\e00d";
}
.icon-presentation:before {
	content: "\e00e";
}
.icon-picture2:before {
	content: "\e00f";
}
.icon-pictures:before {
	content: "\e010";
}
.icon-video:before {
	content: "\e011";
}
.icon-camera2:before {
	content: "\e012";
}
.icon-printer2:before {
	content: "\e013";
}
.icon-toolbox:before {
	content: "\e014";
}
.icon-briefcase2:before {
	content: "\e015";
}
.icon-wallet2:before {
	content: "\e016";
}
.icon-gift:before {
	content: "\e017";
}
.icon-bargraph:before {
	content: "\e018";
}
.icon-grid2:before {
	content: "\e019";
}
.icon-expand:before {
	content: "\e01a";
}
.icon-focus:before {
	content: "\e01b";
}
.icon-edit:before {
	content: "\e01c";
}
.icon-adjustments:before {
	content: "\e01d";
}
.icon-ribbon:before {
	content: "\e01e";
}
.icon-hourglass2:before {
	content: "\e01f";
}
.icon-lock2:before {
	content: "\e020";
}
.icon-megaphone:before {
	content: "\e021";
}
.icon-shield2:before {
	content: "\e022";
}
.icon-trophy2:before {
	content: "\e023";
}
.icon-flag2:before {
	content: "\e024";
}
.icon-map2:before {
	content: "\e025";
}
.icon-puzzle2:before {
	content: "\e026";
}
.icon-basket2:before {
	content: "\e027";
}
.icon-envelope2:before {
	content: "\e028";
}
.icon-streetsign:before {
	content: "\e029";
}
.icon-telescope:before {
	content: "\e02a";
}
.icon-gears:before {
	content: "\e02b";
}
.icon-key2:before {
	content: "\e02c";
}
.icon-paperclip:before {
	content: "\e02d";
}
.icon-attachment:before {
	content: "\e02e";
}
.icon-pricetags:before {
	content: "\e02f";
}
.icon-lightbulb:before {
	content: "\e030";
}
.icon-layers2:before {
	content: "\e031";
}
.icon-pencil2:before {
	content: "\e032";
}
.icon-tools:before {
	content: "\e033";
}
.icon-tools-2:before {
	content: "\e034";
}
.icon-scissors:before {
	content: "\e035";
}
.icon-paintbrush:before {
	content: "\e036";
}
.icon-magnifying-glass:before {
	content: "\e037";
}
.icon-circle-compass:before {
	content: "\e038";
}
.icon-linegraph:before {
	content: "\e039";
}
.icon-mic:before {
	content: "\e03a";
}
.icon-strategy:before {
	content: "\e03b";
}
.icon-beaker:before {
	content: "\e03c";
}
.icon-caution:before {
	content: "\e03d";
}
.icon-recycle:before {
	content: "\e03e";
}
.icon-anchor2:before {
	content: "\e03f";
}
.icon-profile-male:before {
	content: "\e040";
}
.icon-profile-female:before {
	content: "\e041";
}
.icon-bike:before {
	content: "\e042";
}
.icon-wine:before {
	content: "\e043";
}
.icon-hotairballoon:before {
	content: "\e044";
}
.icon-globe2:before {
	content: "\e045";
}
.icon-genius:before {
	content: "\e046";
}
.icon-map-pin:before {
	content: "\e047";
}
.icon-dial:before {
	content: "\e048";
}
.icon-chat:before {
	content: "\e049";
}
.icon-heart2:before {
	content: "\e04a";
}
.icon-cloud:before {
	content: "\e04b";
}
.icon-upload:before {
	content: "\e04c";
}
.icon-download:before {
	content: "\e04d";
}
.icon-target2:before {
	content: "\e04e";
}
.icon-hazardous:before {
	content: "\e04f";
}
.icon-piechart:before {
	content: "\e050";
}
.icon-speedometer2:before {
	content: "\e051";
}
.icon-global:before {
	content: "\e052";
}
.icon-compass2:before {
	content: "\e053";
}
.icon-lifesaver:before {
	content: "\e054";
}
.icon-clock2:before {
	content: "\e055";
}
.icon-aperture:before {
	content: "\e056";
}
.icon-quote:before {
	content: "\e057";
}
.icon-scope:before {
	content: "\e058";
}
.icon-alarmclock:before {
	content: "\e059";
}
.icon-refresh2:before {
	content: "\e05a";
}
.icon-happy:before {
	content: "\e05b";
}
.icon-sad:before {
	content: "\e05c";
}
.icon-facebook:before {
	content: "\e05d";
}
.icon-twitter:before {
	content: "\e05e";
}
.icon-googleplus:before {
	content: "\e05f";
}
.icon-rss:before {
	content: "\e060";
}
.icon-tumblr:before {
	content: "\e061";
}
.icon-linkedin:before {
	content: "\e062";
}
.icon-dribbble:before {
	content: "\e063";
}
.icon-linkedin2:before {
	content: "\f0e1";
}
.icon-vk:before {
	content: "\f189";
}
.icon-behance:before {
	content: "\f1b4";
}
.icon-googleplus2:before {
	content: "\e600";
}
.icon-google-drive:before {
	content: "\e601";
}
.icon-facebook2:before {
	content: "\e602";
}
.icon-instagram:before {
	content: "\e603";
}
.icon-twitter2:before {
	content: "\e604";
}
.icon-feed2:before {
	content: "\e605";
}
.icon-youtube:before {
	content: "\e606";
}
.icon-vimeo:before {
	content: "\e607";
}
.icon-flickr:before {
	content: "\e608";
}
.icon-picassa:before {
	content: "\e609";
}
.icon-dribbble2:before {
	content: "\e60a";
}
.icon-forrst:before {
	content: "\e60b";
}
.icon-deviantart:before {
	content: "\e60c";
}
.icon-steam:before {
	content: "\e60d";
}
.icon-github:before {
	content: "\e60e";
}
.icon-wordpress:before {
	content: "\e60f";
}
.icon-joomla:before {
	content: "\e610";
}
.icon-blogger:before {
	content: "\e611";
}
.icon-tumblr2:before {
	content: "\e612";
}
.icon-yahoo:before {
	content: "\e613";
}
.icon-apple:before {
	content: "\e614";
}
.icon-android:before {
	content: "\e615";
}
.icon-windows8:before {
	content: "\e616";
}
.icon-soundcloud:before {
	content: "\e617";
}
.icon-skype:before {
	content: "\e618";
}
.icon-reddit:before {
	content: "\e619";
}
.icon-lastfm:before {
	content: "\e61a";
}
.icon-stumbleupon:before {
	content: "\e61b";
}
.icon-stackoverflow:before {
	content: "\e61c";
}
.icon-pinterest:before {
	content: "\e61d";
}
.icon-xing:before {
	content: "\e61e";
}
.icon-foursquare:before {
	content: "\e61f";
}
.icon-paypal:before {
	content: "\e620";
}
.icon-html5:before {
	content: "\e621";
}
.icon-css3:before {
	content: "\e622";
}
